<template>
  <div>
    <div>
      <titulo-contas-receber-baixar-titulo />

      <b-tabs>
        <b-tab :title="$t('FINANCEIRO_CONTAS_RECEBER.DADOS_GERAIS')">
          <!-- Gerais -->
          <gerais :form="form" @refs="capturarRefs" class="mt-4">
            <!-- Juros -->
            <template slot="append">
              <div class="col-12 col-md-3">
                <input-text
                  ref="valorDemonstrativo"
                  :label="$t(`PRECOS.VALOR_DA_PARCELA`)"
                  v-model="form.valorDemonstrativo"
                  :disabled="true"
                />
              </div>
              <b-col md="3">
                <input-text
                  ref="juros"
                  :label="$t(`PRECOS.JUROS`)"
                  v-model="form.juros"
                  type="number"
                  :disabled="jurosManual.disabled"
                  @change="calcularJurosManualmente"
                />
              </b-col>
              <b-col md="2" class="my-auto">
                <b-button
                  variant="primary"
                  class="w-100 btn-bootstrap-ajuste-mt"
                  @click="calcularJuros"
                >
                  {{ this.$t('FINANCEIRO_CONTAS_RECEBER.CALCULAR_JUROS') }}
                </b-button>
              </b-col>
              <b-col md="4" class="mt-3 d-flex align-items-end">
                <b-form-group>
                  <b-form-checkbox
                    v-model="jurosManual.calcular"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <label>{{ $t('PRECOS.CALCULO_JUROS_MANUAL') }}</label>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </template>
          </gerais>

          <b-row>
            <b-col>
              <b-button
                variant="primary"
                class="float-right"
                @click="baixarTitulo"
              >
                {{ $t('GERAL.SALVAR') }}
              </b-button>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab :title="$t('FINANCEIRO_CONTAS_RECEBER.DESCONTOS_DO_CONTRATO')">
          <!-- Descontos -->
          <div class="row mb-4 mt-4">
            <div class="col-12">
              <tabela-mostra-descontos :form="descontos" />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
// Utils & Aux:
import verificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import modais from '@/common/utils/modais';
import { mapState } from 'vuex';

// Services & Models:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service';
import { BaixarTitulo } from '@/common/models';
import pdf from '@/common/utils/pdf';

// Components:
import Gerais from '@/views/financeiro/lancamentos/contas-receber/components/baixar-titulo/Gerais';
import TabelaMostraDescontos from '@/views/financeiro/lancamentos/contas-receber/components/TabelaDesconto.vue';
import { InputText } from '@/components/inputs';
import TituloContasReceberBaixarTitulo from '@/views/financeiro/lancamentos/contas-receber/baixar-titulo/components/TituloContasReceberBaixarTitulo';

export default {
  name: 'BaixarTitulo',
  mixins: [verificacaoPreenchimento],
  components: {
    TituloContasReceberBaixarTitulo,
    Gerais,
    InputText,
    TabelaMostraDescontos,
  },
  data() {
    return {
      titleFormula: {
        title:
          'Cálculo automático de juros: <br><br> (Valor * M1 / 100) <br>+<br>  (Valor * J1 / 100 * D1); <br><br> <small>M1: Percentual de Multa <br> J1: Percentual de Juros ao dia <br> D1: Quantidade de Dias </small>',
      },
      refs: [],
      form: new BaixarTitulo({}),
      idsSelecionados: [],
      titulos: [],
      titulosSelecionados: [],
      ehLista: false,
      totalAux: 0,
      totalizadores: {
        somaTotalBaixas: 0,
        somaTotalValorDocBaixas: 0,
        somaTotalJurosBaixas: 0,
        somaTotalDescontoBaixas: 0,
      },
      descontoManual: 0,
      descontos: [],
      descontosFiltro: [],
      idConta: 'modalContas',
      idDesconto: 'modalDesconto',
      ativo: false,
      desabilitarCalculoJuros: false,
      jurosManual: {
        calcular: false,
        disabled: true,
      },
      calculoDescontoManual: {
        calcular: false,
        disabled: true,
      },
      modais: {
        selecionarConta: false,
        selecionarDesconto: false,
      },
    };
  },
  mounted() {
    if (this.baixartitulo.lancamentoSelecionados.length !== 0) {
      this.atribuir();
      this.buscarListaDeContasReceber();

      // se for baixa em título único, verifica se tem juros:
      if (!this.form.juros) {
        this.form.juros = 0;
      }

      return;
    }

    this.$router.push({ name: 'financeiro-contas-a-receber' });
  },
  watch: {
    descontos: 'descontosFiltradosParaTabela',
    'form.desconto': 'calcularDescontoManual',
    'jurosManual.calcular': 'habilitaDesabilitaJurosManual',
    'calculoDescontoManual.calcular': 'habilitaDesabilitaCalculoDescontoManual',
  },
  computed: {
    ...mapState(['baixartitulo']),
  },
  methods: {
    descontosFiltradosParaTabela(descontos) {
      this.descontosFiltro = descontos;
    },
    calcularDescontoManual(e) {
      this.form.total = helpers.formatacaoCasaDecimal(
        this.form.valor - Number(e) + Number(this.form.juros)
      );
      this.form.totalLocal = this.form.total;
    },
    verificarMudancaEmJurosEAtribuir() {
      this.form.total = helpers.formatacaoCasaDecimal(
        Number(this.totalAux) +
          Number(this.form.juros) -
          Number(this.descontoManual)
      );
      this.form.totalLocal = this.form.total;
    },
    calcularJurosManualmente() {
      this.verificarMudancaEmJurosEAtribuir();
    },
    habilitaDesabilitaJurosManual() {
      this.jurosManual.disabled == false
        ? (this.jurosManual.disabled = true)
        : (this.jurosManual.disabled = false);
    },
    habilitaDesabilitaCalculoDescontoManual() {
      this.calculoDescontoManual.disabled == false
        ? (this.calculoDescontoManual.disabled = true)
        : (this.calculoDescontoManual.disabled = false);
    },
    atribuir() {
      const { idsLancamento, lancamentoLista } =
        this.baixartitulo.lancamentoSelecionados;
      this.idsSelecionados = idsLancamento;
      this.ehLista = lancamentoLista;
    },
    calcularSomaTotalDeBaixas() {
      let valorTotal = 0;
      let valorTotalDocumento = 0;
      let valorTotalJuros = 0;
      let valorTotalDesconto = 0;

      this.titulos.forEach(({ valorDemonstrativo, valor, juros, desconto }) => {
        valorTotal += valorDemonstrativo;
        valorTotalDocumento += valor;
        valorTotalJuros += parseFloat(juros);
        valorTotalDesconto += desconto;
      });

      this.form.valor = helpers.formatacaoCasaDecimal(this.form.valor);
      this.totalizadores.somaTotalValorDocBaixas =
        helpers.formatarReal(valorTotalDocumento);
      this.totalizadores.somaTotalBaixas = helpers.formatarReal(valorTotal);
      this.totalizadores.somaTotalJurosBaixas =
        helpers.formatarReal(valorTotalJuros);
      this.totalizadores.somaTotalDescontoBaixas =
        helpers.formatarReal(valorTotalDesconto);
    },
    buscarListaDeContasReceber() {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.buscarListaDeContasReceber(this.idsSelecionados)
        .then((data) => {
          const acresentandoValorDemonstrativo = data.map((item) => {
            this.calcularDesconto();
            return {
              banco: item.banco,
              contaId: item.contaId,
              dataLancamento: item.dataLancamento,
              dataPagamento: item.dataPagamento,
              dataVencimento: item.dataVencimento,
              desconto: item.desconto,
              descontos: item.descontos,
              codigoMatricula: item.codigoMatricula,
              statusPagamento: item.statusPagamento,
              historicoLancamentoDescricao: item.historicoLancamentoDescricao,
              id: item.id,
              juros: item.juros,
              numeroConta: item.numeroConta,
              tipoFormaPagamento: item.tipoFormaPagamento,
              total: item.total,
              valor: item.valor,
              valorDemonstrativo: item.valor - item.desconto,
            };
          });

          this.titulos = acresentandoValorDemonstrativo;
          this.titulosSelecionados = acresentandoValorDemonstrativo;
          this.preencheCampos();
          this.calcularSomaTotalDeBaixas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornaSelecao(valor) {
      const teste = this.titulosSelecionados.includes(valor.item);

      if (teste === true) {
        this.titulosSelecionados = this.titulosSelecionados.filter(
          ({ id }) => id !== valor.item.id
        );
      } else if (teste === false) {
        this.titulosSelecionados.push(valor.item);
      }

      this.ativo = true;
    },
    atribuirJuros(data) {
      this.form.juros = helpers.formatacaoCasaDecimal(data[0].juros);
    },
    calcularJuros() {
      const id = this.ativo ? this.titulosSelecionados : this.titulos;

      const jurosTemp = id.map((item) => {
        return {
          ...item,
          dataPagamento: this.form.dataPagamento,
        };
      });

      this.$store.dispatch(START_LOADING);
      ContaReceberService.calcularJuros(jurosTemp)
        .then((data) => {
          this.atribuirJuros(data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    modificarFormBaseadoEmLista() {
      return this.form;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    baixarTitulo() {
      if (!this.validarFormulario()) return;

      const resultadoFormulario = this.modificarFormBaseadoEmLista();
      this.$store.dispatch(START_LOADING);
      ContaReceberService.baixarTitulo(resultadoFormulario, this.ehLista)
        .then(({ data }) => {
          this.salvo = true;
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO_SUCESSO')
          );

          const confirmacao = mensagem.confirmacao(
            null,
            'Deseja realizar a impressão do recibo ?'
          );

          Promise.resolve(confirmacao).then((confirmacao) => {
            var idsTitulos = [];
            idsTitulos.push(data.data.id);
            if (confirmacao.isConfirmed) {
              this.imprimir({ ids: idsTitulos });
            }
          });

          this.$router.push({ name: 'financeiro-contas-a-receber' });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    descontoSoma(tipo) {
      const descontoSoma = this.descontos.reduce((acc, item) => {
        return (acc += item[tipo]);
      }, 0);

      return descontoSoma;
    },
    calcularDesconto() {
      const descontoSomaPercentual = this.descontoSoma('percentual');

      const descontoSomaValor = this.descontoSoma('valor');

      const constanteDeDivisao = 100;

      const descontoRealizado =
        (descontoSomaPercentual / constanteDeDivisao) *
        this.form.valorDemonstrativo;

      this.form.desconto = helpers.formatacaoCasaDecimal(
        descontoRealizado + descontoSomaValor
      );

      this.form.total = helpers.formatacaoCasaDecimal(
        this.form.valorDemonstrativo -
          descontoRealizado -
          descontoSomaValor -
          Number(this.descontoManual)
      );

      this.form.totalLocal = this.form.total;

      this.totalAux = helpers.formatacaoCasaDecimal(
        this.form.valorDemonstrativo -
          descontoRealizado -
          descontoSomaValor -
          Number(this.descontoManual)
      );
    },
    async atualizarDesconto(form) {
      const sucesso = await ContaReceberService.atualizarDesconto(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PRECOS.DESCONTO_SUCESSO')
          );
          return true;
        })
        .catch((err) => {
          mensagem.showErrors(err);

          return false;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return sucesso;
    },
    preencheCampos() {
      let dataAtual = helpers.formatarDataEn(new Date());
      dataAtual = helpers.adicionarTempoEmData(dataAtual);

      this.titulos.forEach(
        ({ valor, total, dataVencimento, descontos, id, dataLancamento }) => {
          this.form.valor = Number(helpers.formatacaoCasaDecimal(valor));
          this.form.total = total;
          this.form.dataPagamento = dataAtual;
          this.form.dataLancamento = dataLancamento;
          this.form.dataVencimento = dataVencimento;
          this.form.id = id;
          this.form.valorDemonstrativo = helpers.formatacaoCasaDecimal(valor);
          this.descontos = descontos;
        }
      );
      this.calcularDesconto();
    },
    // FUNÇÕES REFS:
    capturarRefs(refs) {
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
    },
    // FUNÇÕES AUXILIARES DE BAIXAR PDF:
    imprimir(id) {
      ContaReceberService.imprimirRecibo(id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
