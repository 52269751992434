<template>
  <b-row class="mb-4">
    <b-col cols="12" md="4">
      <input-date-only-btn
        ref="dataPagamento"
        v-model="form.dataPagamento"
        label="FINANCEIRO_CONTAS_RECEBER.DATA_PAGAMENTO"
      />
    </b-col>
    <b-col cols="12" md="4">
      <input-date-only-btn
        ref="dataVencimento"
        v-model="form.dataVencimento"
        :disabled="true"
        label="FINANCEIRO_CONTAS_RECEBER.DATA_VENCIMENTO"
      />
    </b-col>
    <b-col cols="12" md="4">
      <input-select-search
        ref="tipoPagamento"
        v-model="form.tipoFormaPagamento"
        :label="$t(`FINANCEIRO_CONTAS_RECEBER.TIPO_PAGAMENTO`)"
        :options="opcoes.tipoFormaPagamentos"
        required
      />
    </b-col>

    <b-col cols="12" md="12">
      <selecionar-conta
        class="mt-3"
        @refs="capturarRefs"
        @selecionado="obterSelecionado"
        :required="true"
      />
    </b-col>

    <div class="col-12">
      <h4>{{ $t('FINANCEIRO_CONTAS_RECEBER.VALORES') }}</h4>
    </div>
    <slot name="append"></slot>
    <hr />
    <div class="col-12 col-md-3">
      <input-text
        ref="valorDesconto"
        :label="$t(`PRECOS.VALOR_DESCONTO`)"
        v-model="form.desconto"
        decimal
        type="number"
        min="0"
      />
    </div>
    <div class="col-12 col-md-3">
      <input-text
        ref="valorTotal"
        :label="$t(`PRECOS.VALOR_TOTAL`)"
        type="number"
        v-model="form.totalLocal"
        disabled
      />
    </div>
    <div class="col-12 col-md-3">
      <input-text
        ref="valorRecebido"
        :label="$t(`PRECOS.VALOR_RECEBIDO`)"
        v-model="form.total"
        decimal
        type="number"
        min="0"
      />
    </div>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import {
  InputDateOnlyBtn,
  InputText,
  InputSelectSearch,
} from '@/components/inputs';
import SelecionarConta from '@/components/selecionar-entidades/modal/contas/Index';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputText,
    InputSelectSearch,
    SelecionarConta,
  },
  mounted() {
    this.getTipoFormaPagamento();
  },
  data() {
    return {
      opcoes: {
        tipoFormaPagamentos: [],
      },
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getTipoFormaPagamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-formas-pagamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoFormaPagamentos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES DE COMPONENTES DE SELEÇÃO:
    obterSelecionado(tipo, item) {
      switch (tipo) {
        case 'conta':
          this.form.contaId = item.id;
          this.form.banco = item.banco;
          this.form.numeroConta = item.numeroConta;
          break;
        default:
          break;
      }
    },
    // FUNÇÕES DE VALIDAÇÃO DE CAMPOS
    capturarRefs(refs) {
      this.refs = this.$refs;
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
      this.$emit('refs', this.$refs);
    },
  },
};
</script>

<style scoped>
.mt-3-5 {
  margin-top: 21px;
}
</style>