<template>
  <b-table
    :items="form"
    :fields="campos"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    responsive
    striped
    hover
  />
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    form: { type: [Object, Array], default: Object },
  },
  data() {
    return {
      campos: [
        {
          key: 'descricao',
          label: 'Desconto',
        },
        {
          key: 'percentual',
          label: 'Valor (%)',
          formatter: (value) => {
            if (value) return helpers.formatacaoCasaDecimal(value);
          },
        },
        {
          key: 'valor',
          label: 'Valor (R$)',
          formatter: (value) => {
            if (value) return helpers.formatacaoCasaDecimal(value);
          },
        },
        {
          key: 'tipoDescontoDescricao',
          label: 'Tipo',
        },
      ],
    };
  },
  methods: {},
};
</script>
